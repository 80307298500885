import React,{useContext,useEffect,useRef} from 'react';
import styled from '@emotion/styled';

//context 
import {DirectionContext} from '../context/DirectionContext';

const Section = styled.section`
     background: #f1f1f1;
     padding:100px;
     display: flex;
     justify-content: center;
     align-items: center;
     position: relative;
      & .content{
          position: relative;
          align-items: center;
          width: 100%;
          max-width: 1500px;
      }
      @media (min-width: 320px) and (max-width: 480px){
        padding:0px;
        padding-top: 30px;
        padding-bottom: 30px;
      }
      
`;

const ContentProjects = styled.div`
   position: relative;
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
   grid-gap: 20px;
   max-width:100%;
`;

const Box = styled.div`
    width: 300px;
    margin: 0 auto;
    height: 500px;
    & img{
        width: 100%;
        height: 400px;
        object-fit: cover;
        border: 10px solid #fff;
        box-shadow: 0 15px 30px rgba(0,0,0,.05);
    }
    & .title{
        width: 100%;
        left: 0;
        text-align: center;
        color: var(--gris2);
    }
      & p{
          padding: 0;
          margin: 0;
      }

`;


const Projects = () => {

    const refProyects = useRef('');

    const ContextDirection = useContext(DirectionContext);
    const {guardarRefProyecto} = ContextDirection;

    useEffect(() => {
        guardarRefProyecto(refProyects);
        // eslint-disable-next-line
    }, []);

    return (
        <Section ref={refProyects}>
            <div className="content">
               <ContentProjects>
                  <Box>
                      <img src={require('../assets/projects/1.jpg')} alt=""/>
                      <div className="title">
                         <p>PROYECTOS</p>
                      </div>
                  </Box>
                  <Box>
                      <img src={require('../assets/projects/2.jpg')} alt=""/>
                      <div className="title">
                        <p>ADMINISTRACIÓN Y SUPERVISIÓN DE OBRA</p>
                      </div>
                  </Box>
                  {/* <Box>
                      <img src={require('../assets/projects/3.jpg')} alt=""/>
                      <div className="title">
                         <p>PRESUPUESTO</p>
                      </div>
                  </Box> */}
                  <Box>
                      <img src={require('../assets/projects/4.jpg')} alt=""/>
                      <div className="title">
                        <p>PRESUPUESTOS</p>
                      </div>
                  </Box>
               </ContentProjects>
            </div>
        </Section>
    );
};

export default Projects;