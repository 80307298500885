import React,{createContext,useState} from 'react';

export const DirectionContext = createContext(null);

const DirectionPrivate = ({children})=>{

    //toma la referencia de cada COMPONENTE
    const [refinicio, guardarRefInicio] = useState('');
    const [refproyecto, guardarRefProyecto] = useState('');
    const [refcontacto, guardarRefContacto] = useState('');

    //posicionarse en el componente
    const irInicioFn = ()=>{
        window.scroll(0,refinicio.current.offsetTop);
    }
    
    const irProyectoFn = ()=>{
        window.scroll(0,refproyecto.current.offsetTop);
    }

    const irContactoFn = ()=>{
        window.scroll(0,refcontacto.current.offsetTop);
    }

    return (
        <DirectionContext.Provider 
                value={{
                        guardarRefInicio,
                    guardarRefProyecto,
                    guardarRefContacto,

                    irInicioFn,
                    irProyectoFn,
                    irContactoFn
                }}>
                    {children}
    </DirectionContext.Provider>
    )

}

export default DirectionPrivate;