import React,{Fragment} from 'react';

import Header from '../components/Header';
import Banner from '../components/Banner';
import Section from '../components/Section';
// import SectionTwo from '../components/SectionTwo';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const Home = () => {
    return (
        <Fragment>
            <Header />
            <Banner />
            <Section />
            {/* <SectionTwo /> */}
            <Projects />
            <Contact />
            <Footer />
        </Fragment>
    );
};

export default Home;