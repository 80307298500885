import React,{Fragment} from 'react';
import styled from '@emotion/styled';
import { FaFacebookF,FaInstagram,FaTwitter,FaPhone,FaEnvelope } from "react-icons/fa";

const Section = styled.section`
     background: #f1f1f1;
     padding:80px;
     display: flex;
     justify-content: center;
     align-items: center;
     position: relative;
      & .content{
          position: relative;
          align-items: center;
          width: 100%;
          max-width: 1200px;
      }
      @media (min-width: 320px) and (max-width: 480px){
        padding:0px;
        padding-top: 30px;
        padding-bottom: 40px;
      }
      
`;
const ContentProjects = styled.div`
   position: relative;
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(400px,1fr));
   grid-gap: 30px;
   max-width:100%;
`;
const Box = styled.div`
    width: 400px;
    margin: 0 auto;
    @media (min-width: 320px) and (max-width: 480px){
        width: 200px;
        max-width: 300px; 
    }
`;

const ListNet = styled.ul`
   & li{
       display: inline-block;
       font-size: 3rem;
   }
   & li:nth-child(1n+2){
       margin-left: 10px
   }
   & a{
    color: #222;
   }
`;
const PreFooter = styled.div`
    background: #222;
    text-align: center;
    color: #fff;
    padding: 20px 0;
`;

const Footer = () => {
    return (
        <Fragment>
        <Section>
            <ContentProjects>
                <Box>
                   <h2>Contáctanos</h2>
                   {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p> */}
                   <ul>
                       <li><FaPhone /> 443 312 89 77</li>
                       <li><FaEnvelope/> info@veon.com.mx</li>
                   </ul>
                </Box>
                <Box>
                    <h2>Síguenos</h2>
                   <ListNet>
                       <li>
                           <a rel="noopener noreferrer" target="_blank"href="https://www.facebook.com/VEON-arquitectos-106636967540843/"><FaFacebookF/></a>
                       </li>
                       <li>
                           <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/veon.arq/"><FaInstagram /></a>
                       </li>
                   </ListNet>
                    {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Earum, id!</p> */}
                </Box>
            </ContentProjects>
        </Section>
        <PreFooter>
            <span>Veon Arquitectos 2020</span>
        </PreFooter>
        </Fragment>
    );
};

export default Footer;