import styled from '@emotion/styled';

const Boton = styled.button`
     font-weight: 700;
     text-transform: uppercase;
     border: 1px solid #d1d1d1;
     padding: 1.1rem 3.3rem;
     margin-right: 1rem;
     margin-top: 10px;
     background-color: ${props => props.bgColor ? '#FFB03D' : 'white'};
     color: #222;
     transition: .2s;
     &:last-of-type{
         margin-right: 0;
     }
     &:hover{
         cursor: pointer;
         background-color: ${props => props.bgColor ? '#F79F1E' : 'white'};
     }
`;

export default Boton;