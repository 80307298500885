import React from 'react';
import styled from '@emotion/styled';

import Navegacion from './Navegacion';


const HeaderNav = styled.header`
    border-bottom: 2px solid var(--gris3);
    background: #fff;
    padding: 1rem 0;
    width: 100%;
    position: fixed;
    z-index: 100;
`;
const ContenedorHeader = styled.div`
    max-width: 1200px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    & img{
        width: 50px;
    }
    & div{
        display: flex;
       align-items: center;
    }
    @media (min-width: 320px) and (max-width: 480px) {
        display: flex;
        justify-content: space-between;
        & img{
          width: 20px;
        }
    }

`;

const Header = () => {


    return (
        <HeaderNav>
            <ContenedorHeader>
                <div>
                    <img src={require('../assets/veon.svg')}  alt="veon"/>
                </div>
                <div>
                    <Navegacion />
                </div>
            </ContenedorHeader>
        </HeaderNav>
    );
};

export default Header;