import React from 'react';

import Home from './pages/Home';

// context
import DirectionPrivate from './context/DirectionContext';

const App =() => (
   <DirectionPrivate>
      <Home />
   </DirectionPrivate>
);

export default App;
