import React,{useState} from 'react';
import styled from '@emotion/styled';
import Boton from './ui/Boton';
import axios from 'axios';


const ContentForm = styled.div`
     width: 65%;
     & input,textarea{
        width: 100%;
        margin-top: 20px;
        border: none;
        background-color: #f1f1f1;
        height: 40px;
        padding: 10px 10px;
        border: 1.2px solid #DCDCDC;
        border-radius: 1px;
     }
     & textarea{
         height: auto;
     }
     @media (min-width: 320px) and (max-width: 480px){
        max-width: 90%;
     }
`;

const ErrorMsj = styled.p`
  width: 100%;
  padding: 5px;
  background: #FF6F6F;
  text-align: center;
  border-radius: 2px;
  color: #fff;
`;

const Form = () => {

    const datos = {
        name:'',
        email:'',
        phone:'',
        comment: ''
    }

    const [data, saveData] = useState(datos);
    const [error,saveError] = useState(false);

    const {name,email,phone,comment} = data;

    const handleChange = e =>{
        saveData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async e =>{
        e.preventDefault();
        if(name.trim() === '' || email.trim() === '' || comment.trim() === ''){
             saveError(true);
             return;
        }
        await axios.post('/mail.php', data);
        saveData(datos);
        saveError(false);
    }

    return (
        <ContentForm>
             <h1>CONTÁCTANOS</h1>
             <p>Compártenos tu sueño, trabajaremos en él como si fuera nuestro</p>
             {error ? <ErrorMsj>El campo nombre, email y comentario son hobligatorios</ErrorMsj> : null}
            <form onSubmit={handleSubmit}>
                 <input 
                 type="text"
                 name="name"
                 value={name}
                 onChange={handleChange}
                 placeholder="Nombre"/>
                 <input 
                 type="text" 
                 name="email"
                 value={email}
                 onChange={handleChange}
                 placeholder="Email"/>
                 <input 
                 type="text"
                 onChange={handleChange}
                 name="phone"
                 value={phone} 
                 placeholder="Teléfono"/>
                 <textarea  
                 value={comment}
                 name="comment"
                 onChange={handleChange}
                 placeholder="Comentario" 
                 rows="6"></textarea>
                 <Boton bgColor="true">Enviar</Boton>
            </form>
        </ContentForm>
    );
};

export default Form;