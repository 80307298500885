import React,{useContext,useRef,useEffect} from 'react';
import styled from '@emotion/styled';

import Boton from './ui/Boton';

import {DirectionContext} from '../context/DirectionContext';

const Banner = () => {

    const refBanner = useRef('');

    const ContextDrection = useContext(DirectionContext);
    const {guardarRefInicio,irContactoFn} = ContextDrection;

    useEffect(() => {
        guardarRefInicio(refBanner);
        // eslint-disable-next-line
    }, []);

    return (
        <Container ref={refBanner}>
          <ContainerCol>
              <div>
                  <TextHeading><span>VEON</span> <br/> ARQUITECTOS</TextHeading>
                  <p>Arquitectura comercial • Habitacional • Diseño de interiores • Ingeniería civil • Obra pública</p>
                  <Boton onClick={()=>{irContactoFn()}} bgColor="true">Contáctanos</Boton>
              </div>
          </ContainerCol>
          <ContainerCol className="child2">
             <img src={require('../assets/banner.png')}  alt=""/>
          </ContainerCol>
        </Container>
    );
};

export default Banner;

const Container = styled.div`
      height: 85vh;
      position: relative;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50%,1fr));
      max-width: 100%;
      background: #f1f1f1;
      & .child2{
          background-position: center;
          background-size: cover;
          & img{
             margin-left: -20rem;
             /* margin-top: -8rem; */
             width: 80%;
          }
      }
      @media (min-width: 320px) and (max-width: 480px){
        min-height: 100vh;
        grid-template-columns: repeat(auto-fit, minmax(100%,1fr));
        & .child2{
          background-position: center;
          background-size: cover;
          & img{
             margin-left: 0rem;
             margin-top: 0rem;
             width: 70%;
          }
      }
    }

`;
const ContainerCol = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        & div{
            width: 60%;
        }
        @media (min-width: 320px) and (max-width: 480px){
            & div{
                width: 80%;
            }
        }
`;
const TextHeading = styled.h1`
    & span{
        font-size: 12rem;
        font-weight: 900;
    }
    font-size: 8.5rem;
    line-height: 1em;
    margin-bottom: 20px;
    @media (min-width: 320px) and (max-width: 480px){
        font-size: 3.5rem;
        & span{
            font-size: 4rem;
        }
    }
`;