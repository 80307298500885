import React from 'react';
import styled from '@emotion/styled';

const Container = styled.section`
      height: 60vh;
      position: relative;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50%,1fr));
      max-width: 100%;
      @media (min-width: 320px) and (max-width: 480px){
        grid-template-columns: repeat(auto-fit, minmax(100%,1fr)); 
        height: 100vh;
      }
`;
const ContentColImg = styled.div`
    background: #f1f1f1;
    /* transform: translateY(-40px); */
    height: 60vh;
    & img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
const ContentCol =styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    & div{
        width: 70%;
    }
    & h2{
        font-size: 4rem;
        color: var(--gris);
    }

    @media (min-width: 320px) and (max-width: 480px){
        & div{
            width: 75%;
        }
        & h2{
            text-align: justify;
            font-size: 2rem;
            color: var(--gris);
        }
    }
`;

const Section = () => {
    return (
        <Container>
            <ContentColImg >
                <img src={require('../assets/sec1.jpg')} alt=""/>
            </ContentColImg>

            <ContentCol >
                 <div>
                     <h2>"Creo en la arquitectura emocional. Es muy importante para la humanidad que la arquitectura emocione por su belleza."</h2>
                     <p>- <i>Luis Barragán</i></p>
                 </div>
            </ContentCol>
        </Container>
    );
};

export default Section;