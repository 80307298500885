import React,{useContext} from 'react';
import styled from '@emotion/styled';

import {DirectionContext} from '../context/DirectionContext';

const Nav = styled.nav`
   padding-left: 2rem;
   a{
       font-size: 1.8rem;
       margin-left: 2rem;
       color: var(--gris2);
       font-family: 'PT Sans', sans-serif;
       &:last-of-type{
           margin-right: 0;
       }
   }
`;

const Navegacion = () => {

    const directionContext = useContext(DirectionContext);
    const {irInicioFn,irProyectoFn,irContactoFn} = directionContext;

    return (
        <Nav>
            <a href="#!" onClick={()=>{irInicioFn()}}>Inicio</a>
            <a href="#!" onClick={()=>{irProyectoFn()}}>Servicios</a>
            <a href="#!" onClick={()=>{irContactoFn()}}>Contacto</a>
        </Nav>
    );
};

export default Navegacion;