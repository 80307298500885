import React,{useContext,useEffect,useRef} from 'react';
import styled from '@emotion/styled';

import Form from './Form';


import {DirectionContext} from '../context/DirectionContext';


const Container = styled.section`
      min-height: 60vh;
      position: relative;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50%,1fr));
      max-width: 100%;
      @media (min-width: 320px) and (max-width: 480px){
        min-height: 100vh;
        grid-template-columns: repeat(auto-fit, minmax(100%,1fr));
      }
`;

const ContentCol =styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    & iframe{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (min-width: 320px) and (max-width: 480px){
        :nth-child(2){
            padding-top: 30px;
            padding-bottom: 40px;
        }
    }
`;


const Contact = () => {

    const refContacto = useRef('');

   const directionContext = useContext(DirectionContext);
   const {guardarRefContacto} = directionContext;

   useEffect(() => {
       guardarRefContacto(refContacto);
       // eslint-disable-next-line
   }, []);

    return (
        <Container ref={refContacto}>
            <ContentCol>
            <iframe title="map veon arq" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.827968195186!2d-101.2278239844536!3d19.67734348674574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0c3c920cb285%3A0x90164def625338e1!2sJardines%20de%20La%20Loma%2C%20Morelia%2C%20Mich.!5e0!3m2!1ses-419!2smx!4v1584140205106!5m2!1ses-419!2smx" frameBorder="0"  allowFullScreen=""></iframe>
           </ContentCol>
           <ContentCol>
               <Form />
           </ContentCol>
        </Container>
    );
};

export default Contact;